// Client for tvHead API.

const getCurrentImageData = async function({currentImageData = {}, setCurrentImageData, forceRefresh = false}) {
    const currentImageId = currentImageData?.Image?.id;
    try {
        const response = await fetch(`${process.env.REACT_APP_GUILLOTINE_BACKEND_URL}/v1/images/current-image?forceRefresh=${forceRefresh}`)
        const responseJson = await response.json();
        if (forceRefresh) {
            console.log("Forcing refresh.");
        }
        if (forceRefresh && responseJson?.Image?.id === currentImageId) {
            console.log("Image is the same as current image.  Not updating.");
            await getCurrentImageData({currentImageData, setCurrentImageData, forceRefresh: false});
            return
        }
        setCurrentImageData(responseJson);
        return
    } catch (error) {
        console.log(error);
        throw error;
    }
}

const getSettingByKey = async function(key) {
    try {
        const response = await fetch(`${process.env.REACT_APP_GUILLOTINE_BACKEND_URL}/tv-head-settings/v1/${key}?create=true`)
        const responseJson = await response.json();
        return responseJson;
    } catch (error) {
        console.log(error);
        throw error;
    }
}

const updateSetting = async function({key, value}) {
    try {
        const response = await fetch(`${process.env.REACT_APP_GUILLOTINE_BACKEND_URL}/tv-head-settings/v1/${key}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({value})
        })
        const responseJson = await response.json();
        return responseJson;
    } catch (error) {
        console.log(error);
        throw error;
    }
}
const tvHeadClient = {
    getCurrentImageData,
    getSettingByKey,
    updateSetting,
};
export default tvHeadClient;