import React, { useRef, useMemo } from 'react'
import { qwerty, rowOffset, colOffset, rowXOffset, keyWidth } from './keyParams';
import * as THREE from 'three';
import { useFrame, useLoader } from 'react-three-fiber';
import { useSprings } from 'react-spring';

const tempObject = new THREE.Object3D();
const tempColor = new THREE.Color();

// TODO: reset all key positions on blur
// TODO: set stiffer config for key springs

const numKeys = Object.keys(qwerty).length;
function KeyboardKeys(props) {
    const { kbKeys } = props;
    const ref = useRef();
    const [keyHeightSprings, setHeights] = useSprings(numKeys, i => ({y: 0}));
    const colorArray = useMemo(() => Float32Array.from(new Array(numKeys).fill().flatMap((_, i) => tempColor.set('#87c5cb').toArray())), [])
    useFrame(() => {
        if(!kbKeys) return;
        setHeights(i => {
            const keyObj = kbKeys[Object.keys(kbKeys)[i]];
            return {y: keyObj.pos === 'DOWN' ? -15: 0};
        });
        for (let index = 0; index < Object.keys(kbKeys).length; index++) {
            const keyId = Object.keys(kbKeys)[index];
            const keyObj = kbKeys[keyId];
            tempObject.position.set(
                keyObj.col * colOffset + rowXOffset[keyObj.row],
                keyHeightSprings[index].y.value,
                keyObj.row * rowOffset,
            );
            tempColor.set(keyObj.pos === 'DOWN' ? '#87c5cb': 'orange').toArray(colorArray, index * 3);
            ref.current.geometry.attributes.color.needsUpdate = true
            
            tempObject.updateMatrix();
            ref.current.setMatrixAt(index, tempObject.matrix);
        }
        ref.current.instanceMatrix.needsUpdate = true

    })
    return (
            <instancedMesh ref={ref} args={[null, null, numKeys]} >
                <boxBufferGeometry attach="geometry" args={[keyWidth, keyWidth, keyWidth]}>
                    <instancedBufferAttribute attachObject={['attributes','color']} args={[colorArray, 3]} />
                </boxBufferGeometry>
                {/* <meshPhongMaterial attach="material" vertexColors={THREE.VertexColors} /> */}
                <meshStandardMaterial attach="material" vertexColors={THREE.VertexColors}/>
            </instancedMesh>
    
    )
}

function KeyBoard(props) {
    const width = 300;
    const depth = 140;
    const thickness = 20;
    const texture = useLoader(THREE.TextureLoader, '/flakes.png')
    const normalMap = useMemo(() => {
        const clone = texture.clone(true)
        clone.needsUpdate = true
        return clone
      }, [texture])
    return (
        <group rotation={[.2,0,0]}
        position={[-1.2, -2, 1]}
        scale={[.01, .01 ,.01]}>

            <KeyboardKeys {...props}></KeyboardKeys>
            <mesh position={[width/2, -15, depth/2]}>
                <boxBufferGeometry attach="geometry" args={[width, thickness, depth]}/>
                {/* <meshStandardMaterial attach="material" color={'#eae1be'}/> */}
                <meshPhysicalMaterial
                    attach="material"
                    metalness={0.2}
                    roughness={0.7}
                    color={'#eae1be'}
                    normalMap={normalMap}
                    normalScale={[0.5, 0.5]}
                    normalMap-wrapS={THREE.RepeatWrapping}
                    normalMap-wrapT={THREE.RepeatWrapping}
                    normalMap-repeat={[20, 20]}
                    //normalMap-anisotropy={16}
                    />
            </mesh>
        </group>
    )

}

export { KeyBoard }
