import screenText from "./screenText";
export default class EyeWidget {
    constructor(eyePosition, eyeRadius) {
        this.eyePosition = eyePosition ? eyePosition : {x: 0, y: 0};
        this.pupilPosition = {x: 0, y: 0};
        this.eyeRadius = 50;
        this.maxOpenPosition = 0.7;
        this.squintPosition = 0.2;
        this.openPosition = this.maxOpenPosition;
        this.isOpen = true;
        this.pupilVelocity = 0.1;
        this.blinkingState = 'OPEN';
        this.blinkVelocity = 0.2;
        this.eyeCanvas = null;
    }
    setup({p5}) {
        this.eyeCanvas = p5.createGraphics(p5.windowWidth, p5.windowHeight);
    }
    update(controllerState) {
        console.log(controllerState);
        if (!controllerState?.axes) {
            return;
        }  
        const {axes: [leftStickX, leftStickY, rightStickX, rightStickY]} = controllerState;
        // this.pupilPosition.x += leftStickX * this.pupilVelocity;
        // this.pupilPosition.x = Math.min(Math.max(this.pupilPosition.x, -1), 1);
        const mix_fraction = 0.15;
        this.pupilPosition.x = (this.pupilPosition.x * (1- mix_fraction)) +  (leftStickX * -1 * mix_fraction);
        // this.pupilPosition.y += leftStickY * this.pupilVelocity;
        // this.pupilPosition.y = Math.min(Math.max(this.pupilPosition.y, -1), 1);
        this.pupilPosition.y = (this.pupilPosition.y *(1 - mix_fraction)) + (leftStickY * mix_fraction);
        // open and close with left trigger
        // squint with left bumper
        if (this.blinkingState === 'OPEN' && controllerState.buttons[6].pressed) {
            this.blinkingState = 'CLOSING';
        } else if (this.blinkingState === 'OPEN' && controllerState.buttons[4].pressed) {
            this.blinkingState = 'SQUINTING';
        } else if (this.blinkingState === 'SQUINTING') {
            this.openPosition -= this.blinkVelocity;
            if (this.openPosition <= this.squintPosition) {
                this.openPosition = this.squintPosition;
                this.blinkingState = 'SQUINTED';
            }
        } else if (this.blinkingState === 'SQUINTED' && !controllerState.buttons[4].pressed) {
            this.blinkingState = 'OPENING';
        } else if (this.blinkingState === 'CLOSING') {
            this.openPosition -= this.blinkVelocity;
            if (this.openPosition <= 0) {
                this.openPosition = 0;
                this.blinkingState = 'CLOSED';
            }
        } else if (this.blinkingState === 'CLOSED' && !controllerState.buttons[6].pressed) {
            this.blinkingState = 'OPENING';
        } else if (this.blinkingState === 'OPENING') {
            this.openPosition += this.blinkVelocity;
            if (this.openPosition >= this.maxOpenPosition) {
                this.openPosition = this.maxOpenPosition;
                this.blinkingState = 'OPEN';
            }
        }

        return true;
    }
    render({target, p5}) {
        target.push();
        this.eyeRadius = this.eyeCanvas.height / 3;
        // screenText(`${this.pupilPosition.x} ${this.pupilPosition.y}`, this.eyeCanvas, 'CENTER', 'BOTTOM', p5);
        this.eyeCanvas.fill('rgba(0,255,0, 1)');
        //draw eye outline
        this.eyeCanvas.circle(this.eyeCanvas.width/2, this.eyeCanvas.height/2, this.eyeRadius*2);
        //draw pupil
        this.eyeCanvas.fill('rgba(10,10,10, 1)');
        const pupilRadius = this.eyeRadius * 0.5;
        const pupilX = this.eyeCanvas.width/2 + this.pupilPosition.x * this.eyeRadius * 0.5;
        const pupilY = this.eyeCanvas.height/2 + this.pupilPosition.y * this.eyeRadius * 0.5;
        this.eyeCanvas.circle(pupilX, pupilY, pupilRadius);
        // erase eye for open position
        this.eyeCanvas.stroke('rgba(255,0,0, 1')
        // this.eyeCanvas.ellipse(this.eyeCanvas.width/2, this.eyeCanvas.height/2, this.eyeRadius*2, this.eyeRadius*2*this.openPosition);
        this.eyeCanvas.erase();
        // bottom lid
        this.eyeCanvas.beginShape();
        this.eyeCanvas.curveVertex(this.eyeCanvas.width / 2 - this.eyeRadius, this.eyeCanvas.height);
        this.eyeCanvas.curveVertex(this.eyeCanvas.width / 2 - this.eyeRadius, this.eyeCanvas.height);
        this.eyeCanvas.curveVertex(this.eyeCanvas.width / 2 - this.eyeRadius, this.eyeCanvas.height / 2);
        this.eyeCanvas.curveVertex(this.eyeCanvas.width/2, this.eyeCanvas.height / 2 + this.eyeRadius * this.openPosition);
        this.eyeCanvas.curveVertex(this.eyeCanvas.width / 2 + this.eyeRadius, this.eyeCanvas.height / 2);
        this.eyeCanvas.curveVertex(this.eyeCanvas.width / 2 + this.eyeRadius, this.eyeCanvas.height);
        this.eyeCanvas.curveVertex(this.eyeCanvas.width / 2 + this.eyeRadius, this.eyeCanvas.height);
        this.eyeCanvas.endShape();

        this.eyeCanvas.beginShape();
        this.eyeCanvas.curveVertex(this.eyeCanvas.width / 2 - this.eyeRadius, 0);
        this.eyeCanvas.curveVertex(this.eyeCanvas.width / 2 - this.eyeRadius, 0);
        this.eyeCanvas.curveVertex(this.eyeCanvas.width / 2 - this.eyeRadius, this.eyeCanvas.height / 2);
        this.eyeCanvas.curveVertex(this.eyeCanvas.width / 2, this.eyeCanvas.height / 2 - this.eyeRadius * this.openPosition);
        this.eyeCanvas.curveVertex(this.eyeCanvas.width / 2 + this.eyeRadius, this.eyeCanvas.height / 2);
        this.eyeCanvas.curveVertex(this.eyeCanvas.width / 2 + this.eyeRadius, 0);
        this.eyeCanvas.curveVertex(this.eyeCanvas.width / 2 + this.eyeRadius, 0);
        this.eyeCanvas.endShape();
        this.eyeCanvas.noErase();
        target.image(this.eyeCanvas, this.eyePosition.x, this.eyePosition.y);
        target.pop();
    }
    updateAndDraw({controllerState, target, p5}) {
        const updated = this.update(controllerState);
        if (!updated) {
            return;
        }
        this.render({target, p5});
    }
}