import constants from '../constants';
const processWeatherResult = function(weatherResponse) {
    const hourlyCount = 8;
    const { current } = weatherResponse;
    console.log(weatherResponse.hourly.slice(0, hourlyCount));
    let hourlies = weatherResponse.hourly.slice(0, hourlyCount);
    let min = null;
    let max = null;
    hourlies.forEach(element => {
        if (min === null || element.temp < min) min = element.temp;
        if (max === null || element.temp > max) max = element.temp;
    });
    hourlies = hourlies.map((hourly) => {
        const fillpct = (hourly.temp - min) * 100/(max - min);
        return {...hourly, min, max, fillpct};
    });
    return {current, hourlies, loaded: true};
}
const getWeather = async function(setWeather) {
    try {
        const lat = constants.latitude;
        const lon = constants.longitude;
        const part = 'minutely'; // exclude whatever this is.
        const weather_api_key = process.env.REACT_APP_OPEN_WEATHER_API_KEY;
        const url = `${constants.weatherApiURL}?lat=${lat}&lon=${lon}&exclude=${part}&appid=${weather_api_key}&units=imperial`;
        const response = await fetch(url);
        const responseJson = await response.json();
        setWeather(processWeatherResult(responseJson))
        
    } catch (error) {
        console.log(error);
        throw error;
    }

}

export { getWeather };