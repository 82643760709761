const userSignifier = '>:';
function clamp(value, min, max) {
    if (value < min) return min;
    if (value > max) return max;
    return value;
}
const textSplitter = function({ terminalHistory, maxWidth, textSelection }) {
    const output = [];
    let  [selectionStart, selectionEnd] = textSelection;
    terminalHistory.forEach((historyLine, historyIndex) => {
        const isTerminalInput = (historyIndex === terminalHistory.length  - 1);
        const { source } = historyLine;
        const historyText = 
            `${source === 'USER' ? userSignifier : ''}${historyLine.text}`;

        if (!historyText) return;
        const historyLines = historyText.split('\n');

        historyLines.forEach((historyLine) => {
            while (historyLine.length > maxWidth) {
                const toPush = historyLine.substring(0, maxWidth);
                output.push({text: toPush, textSelection: isTerminalInput ? {selectionStart, selectionEnd} : null});
                historyLine = historyLine.substring(maxWidth, historyLine.length)
            }
            const outputSelectionStart = clamp(selectionStart, 0, historyLine.length);
            const outputSelectionEnd = clamp(selectionEnd, 0, historyLine.length);
            output.push({text: historyLine, textSelection: isTerminalInput ? {selectionStart: outputSelectionStart, selectionEnd: outputSelectionEnd} : null});
        })
    })
    return output;
}
const writeText = (input) => {
    const {textSelection, text, context, lineNum, fontSize, color, bgColor} = input;
    if (textSelection) {
        const firstText = text.substring(0, textSelection.selectionStart);
        const highlightedText = text.substring(textSelection.selectionStart, textSelection.selectionEnd);
        // context.save();
        context.beginPath();
        context.font = `bold ${fontSize}px 'Xanh Mono'`;
        context.textAlign = 'left';
        context.textBaseline = 'middle';
        context.fillStyle = 'orange';
        context.fillText(firstText, 0 + fontSize / 2, 0 + fontSize + (fontSize * lineNum));
        context.stroke();
        // context.restore();
        var ftWidth = context.measureText(firstText).width;

        context.save();
        context.beginPath();
        var htWidth = context.measureText(highlightedText).width;
        context.fillRect(0 + fontSize / 2 + ftWidth, 0 + fontSize/2 + (fontSize * lineNum), htWidth, parseInt(fontSize, 10));
        context.fillStyle = bgColor;
        // draw cursor
        if (textSelection.selectionStart === textSelection.selectionEnd) {
            context.fillStyle = color;
            const cursorWidth = context.measureText('|').width;
            context.fillText('|', 0 + fontSize / 2 + ftWidth - cursorWidth/2, 0 + fontSize + (fontSize * lineNum));

        } else {
            context.fillText(highlightedText, 0 + fontSize / 2 + ftWidth, 0 + fontSize + (fontSize * lineNum));
        }
        context.stroke();
        context.restore();


        const endText = text.substring(textSelection.selectionEnd, text.length);
        context.beginPath();
        context.save();
        context.font = `bold ${fontSize}px 'Xanh Mono'`;
        context.textAlign = 'left';
        context.textBaseline = 'middle';
        context.fillStyle = 'orange';
        context.fillText(endText, 0 + fontSize / 2 + ftWidth + htWidth, 0 + fontSize + (fontSize * lineNum));
        context.stroke();
        context.restore();
        
        // context.fillStyle = bgColor;
        // context.fillText(text, 0 + fontSize / 2, 0 + fontSize + (fontSize * lineNum));
        // context.stroke();
        // context.restore();

    } else {
        context.beginPath();
        context.font = `bold ${fontSize}px 'Xanh Mono'`;
        context.textAlign = 'left';
        context.textBaseline = 'middle';
        context.fillStyle = color;
        context.fillText(text, 0 + fontSize / 2, 0 + fontSize + (fontSize * lineNum));
        context.stroke();
    }
    // console.log(text);
    
}
export { textSplitter, userSignifier, writeText };
