const commandFunction = function({raw, argv, printToTerminal, history, clearTerminalHistory}) {
    clearTerminalHistory();
    
}
const commandName = 'clear';
const manPage =
    'Name: Clear' +
    '\ncommand: clear' +
    '\n------------------------------' +
    '\nDescription: Clear the screen of terminal history.'
export {
    commandName,
    commandFunction,
    manPage,
};
