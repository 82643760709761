const TvHeadMedia = (props) => {
    const { url } = props;
    return (
        <img className="slideshowImage" src={url} alt="tv head media"/>
    )
}

const DisplayContainer = (props) => {
    const { url } = props;
    return (
        <>
            <TvHeadMedia url={url} />
        </>
    )
}
export default DisplayContainer;