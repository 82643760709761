const getByKey = function(key, fallbackValue) {
    console.log('getByKey:', key);
    const retVal = localStorage.getItem(key);
    return retVal !== null ? JSON.parse(retVal) : fallbackValue;
}
const setByKey = function(key, value) {
    console.log('setByKey:', key, value);

    return localStorage.setItem(key, JSON.stringify(value));
}

export {getByKey, setByKey }
