import React, { useState, useEffect, useRef } from "react";
import Sketch from "react-p5";
import { SketchContext } from "../components/comsScreen/sketchContext";

const SketchBackground = ({registerCallbacks, clearCallbacks, id}) => {
    useEffect(() => {
        const draw = (p5) => {
            console.log('drawing bg')
            p5.background(0);
        }
        registerCallbacks({ draw, id})
        return () => { clearCallbacks(id)}
    }, [id, registerCallbacks, clearCallbacks])
    return null
}
const MovingCircle = (props) => {
    const [forward, setForward] = useState(true);
    const [x, setX] = useState(0);
    const ref = useRef(forward)
    const xRef = useRef(x)
    const reverse = () => {
        ref.current = !ref.current;
        setForward(ref.current);
    }
    // const [x, setX] = useState(0)
    const {y, registerCallbacks, id, clearCallbacks} = props
    const setup = (p5, canvasParentRef) => {
        console.log('movin circle setup')
    }
    const draw = (p5) => {
        p5.ellipse(xRef.current, y, 70, 70);
        let nextX = xRef.current - 1
        if (ref.current) {
            nextX = xRef.current + 1;
        }
        if ((nextX < 50 && !ref.current) || (nextX > 500 && ref.current)) reverse()
        xRef.current = nextX
        setX(nextX)
        return
    }
    useEffect(() => {
        registerCallbacks({ draw, setup, id })
        return () => { clearCallbacks(id)}
    }, [y, id])
    return (<div>circle</div>)
}
const useStateRef = (defaultValue) => {
    const [state, setState] = useState(defaultValue);
    return [state, setState, useRef(defaultValue)]
}
const CirclesSketch = (props) => {
    console.log("new run")
    const [sketchCallbacks, setSketchCallbacks, stateRef] = useStateRef({})
    const [frame, setFrame] = useState(0);
    const [moverCount, setMoverCount] = useState(0);
    // const stateRef = useRef();
    stateRef.current = sketchCallbacks;
    const incrementMovers = () => {
        setMoverCount(moverCount + 1)
    }
    const decrementMovers = () => {
        if (moverCount <= 0) {
            setMoverCount(0)
            return;
        }
        setMoverCount(moverCount - 1)
    }
    const registerCallbacks = ({id, ...widgetCallbacks}) => {
            const newDrawCallbacks = {...stateRef.current};
            newDrawCallbacks[id] = widgetCallbacks;
            // console.log(newDrawCallbacks)
            setSketchCallbacks(newDrawCallbacks)
            stateRef.current = newDrawCallbacks
    }
    const sketchLifecycleSteps = ["setup", "draw"]
    const sketchLifecycleProps = {}
    sketchLifecycleSteps.forEach((step) => {
        sketchLifecycleProps[step] = ((p5) => {
            // console.log(step)
            Object.keys(sketchCallbacks).forEach((id) => {
                sketchCallbacks[id]?.[step]?.(p5);
            })
        })
    })
    // console.log(sketchLifecycleProps)
    const clearCallbacks = (id) => {
        if (stateRef.current.hasOwnProperty(id)) {
            let newDrawCallbacks = stateRef.current;
            delete newDrawCallbacks[id]
            setSketchCallbacks(newDrawCallbacks)
            stateRef.current = newDrawCallbacks;
        }
    }
    // const draw = (p5) => {
    //     p5.background(0);
    //     // console.log('clearbg')
    //     // console.log(drawCallbacks)
    //     Object.keys(sketchCallbacks).forEach((id) => {
    //         sketchCallbacks[id]?.draw(p5);
    //     })
    // };
    const setup = (p5, canvasParentRef) => {
        p5.createCanvas(500, 500).parent(canvasParentRef);
        // setupCallbacks.forEach(setupCall => {
        //     setupCall(p5, canvasParentRef)
        // });

    };
    const getMovers = (i) => {
        const movers = []
        for (let index = 0; index < i; index++) {
            movers.push((
            <MovingCircle
                registerCallbacks={registerCallbacks}
                clearCallbacks={clearCallbacks}
                y={50*(index+1)}
                id={`mover${index}`}
                key={index}>
            </MovingCircle>))
            
        }
        return movers
    }
    return (
        <>
        <Sketch {...sketchLifecycleProps} setup={setup} frame={frame}>
        </Sketch>
        <SketchBackground registerCallbacks={registerCallbacks} clearCallbacks={clearCallbacks} id="background"/>
        <div>
            <button onClick={incrementMovers}>+</button><button onClick={decrementMovers}>-</button>
        </div>
        {
            getMovers(moverCount).map((mover) => mover)
        }
        </>
    )
}


const SketchWrapper = (props) => {
    const [sketchCallbacks, setSketchCallbacks, stateRef] = useStateRef({})

    const clearCallbacks = (id) => {
        if (stateRef.current.hasOwnProperty(id)) {
            let newDrawCallbacks = stateRef.current;
            delete newDrawCallbacks[id]
            setSketchCallbacks(newDrawCallbacks)
            stateRef.current = newDrawCallbacks;
        }
    }
    const registerCallbacks = ({id, ...widgetCallbacks}) => {
        const newDrawCallbacks = {...stateRef.current};
        newDrawCallbacks[id] = widgetCallbacks;
        // console.log(newDrawCallbacks)
        setSketchCallbacks(newDrawCallbacks)
        stateRef.current = newDrawCallbacks
    }
    const sketchLifecycleSteps = ["setup", "draw"]
    const sketchLifecycleProps = {}
    sketchLifecycleSteps.forEach((step) => {
        sketchLifecycleProps[step] = ((p5) => {
            // console.log(step)
            Object.keys(sketchCallbacks).forEach((id) => {
                sketchCallbacks[id]?.[step]?.(p5);
            })
        })
    })
    return (
        <SketchContext.Provider value={{clearCallbacks, registerCallbacks}}>

        </SketchContext.Provider>
    )

}

export { CirclesSketch }