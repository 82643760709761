const commandFunction = function(input) {
    const {printToTerminal, history, terminalHistory, setSaveTerminalHistory} = input;
    printToTerminal('navigating to gan');
    const waitCount = 3;
    let lastText = terminalHistory[terminalHistory.length -1].text;
    function waitDot() {
        lastText += '.';
        terminalHistory[terminalHistory.length -1].text = lastText;
        setSaveTerminalHistory(terminalHistory);
    }
    for (let index = 0; index < waitCount; index++) {
        setTimeout(waitDot, (index + 1) * 500);
    }
    setTimeout(() => {history.push('/sockets');}, (waitCount + 1) * 500);
}

    
const commandName = 'gan';
const manPage = 
'Name: gan' +
'\ncommand: gan' +
'\n------------------------------' +
'\nDescription: Navigate directly to Sean\'s gan'


export {
    commandName,
    commandFunction,
    manPage,
};
