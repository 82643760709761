import { useEffect, useState } from "react";
import useInterval from "./useInterval";
import tvHeadClient from "../services/tvHeadClient.js";

export default function useTvHeadSetting(settingsKey, defaultValue = "", refreshFrequency = null) {
    const [setting, setSetting] = useState({ key: settingsKey, value: defaultValue, dataType: 'STRING', updatedAt: new Date() });

    useEffect(() => {
        if (!settingsKey) return
        tvHeadClient.getSettingByKey(settingsKey).then((responseJson) => {
            if (!responseJson) return
            console.log(responseJson)
            setSetting(responseJson);
        });
    }, [settingsKey]);
    const updateSetting = function({key, value, dataType}) {
        console.log(key)
        console.log(value)
        console.log(dataType)
        tvHeadClient.updateSetting({key, value, dataType}).then((setting) => {
            setSetting(setting);
        });
    }
    useInterval(() => {
        tvHeadClient.getSettingByKey(settingsKey).then((responseJson) => {
            if (!responseJson) return
            console.log(responseJson)
            setSetting(responseJson);
        });
    }, refreshFrequency);
    return [setting, updateSetting];

}