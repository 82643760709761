import React, { useRef, useState } from 'react'
import { textSplitter, userSignifier, writeText } from '../services/termService';
import Sketch from '../services/reactP5';

const textPadding = 20;
let emShaderActive = false;
let ddShaderActive = false;
const hudGreen = [15, 252, 3];
const showWidgets = false;
function radarWidget(target, p5) {
    const radarWidth = 200;

    target.push();
    // target.rectMode(CENTER);
    target.noFill();
    target.stroke(...hudGreen);
    target.translate(target.width - 110, 110);
    for (let radius = radarWidth; radius > 0; radius -= 50) {
        target.circle(0, 0, radius);
    }
    target.circle(0, 0, 150);
    target.circle(0, 0, 100);
    target.circle(0, 0, 50);
    // target.circle(0, 0, 10);
    target.fill(...hudGreen);
    target.triangle(0, -10, -8, 8, 8, 8);
    target.noFill();
    const pulseRadius = (p5.millis() / 7 % 500);
    if (pulseRadius <= radarWidth) {
        target.circle(0, 0, pulseRadius);
    }
    target.angleMode(p5.DEGREES);
    target.rotate((p5.millis() / 100) % 360);
    for (let lineAlpha = 0; lineAlpha <= 256; lineAlpha += 10) {
        target.stroke(...hudGreen, lineAlpha);
        target.rotate(1);
        target.line(0, 0, 0, radarWidth/2);
    }
    target.pop();
}

const barWidget = function(target, p5) {
    const maxHeight = 70;
    const barWidth = 20;
    const barPadding = 15;
    const barCount = 4;
    let actualHeight;
    const milli = p5.millis();
    const speeds = [1000, 802, 600, 300];
    for (let index = 0; index <= barCount; index++) {
        actualHeight = (Math.sin(milli / speeds[index]) + 1) / 2 * maxHeight;
        target.rect(textPadding + index * (barWidth + barPadding), maxHeight - actualHeight + textPadding, barWidth, actualHeight);
    }
    target.rect(textPadding, maxHeight + textPadding + 5, barCount * (barWidth + barPadding) - barPadding, 5);
}
const ScreenSketch = (props) => {
    const ref = useRef();
    console.log(ref);
    const { sketchWidth, sketchHeight, setP5CanvasCreated} = props;
    const { textSelection, textContents, terminalHistory } = props;

    const toDisplay = [...terminalHistory.map((e) => {
      return `${e.source === 'USER' ? userSignifier : ''}${e.text}`;
    }), `>:${textContents}`].join('\n');

    const color = 'orange', fontSize = 50;
    let [pg0, setpg0] = useState(undefined);
    let [pg1, setpg1] = useState(undefined);
    let [pg2, setpg2] = useState(undefined);
    let [emShader, setEMShader] = useState(undefined);
    let [ddShader, setDDShader] = useState(undefined);
    let [fontVCR, setfontVCR] = useState(undefined);
    const context = (pg0) ? pg0.drawingContext : false;


    const preload = (p5) => {
        fontVCR = p5.loadFont(`${process.env.PUBLIC_URL}/assets/fonts/VCR_OSD_MONO_1.001.ttf`);
        ddShader = p5.loadShader(`${process.env.PUBLIC_URL}/assets/shaders/displayDistort.vert`, `${process.env.PUBLIC_URL}/assets/shaders/displayDistort.frag`);
        emShader = p5.loadShader(`${process.env.PUBLIC_URL}/assets/shaders/em.vert`, `${process.env.PUBLIC_URL}/assets/shaders/em.frag`);
        setEMShader(emShader);
        setfontVCR(fontVCR);
        setDDShader(ddShader);
    }

	const setup = (p5, canvasParentRef) => {
		p5.createCanvas(sketchWidth, sketchHeight, p5.WEBGL).parent(canvasParentRef);
        setP5CanvasCreated(true);
        p5.noStroke();
        pg0 = p5.createGraphics(sketchWidth, sketchHeight);
        pg1 = p5.createGraphics(sketchWidth, sketchHeight, p5.WEBGL);
        pg2 = p5.createGraphics(sketchWidth, sketchHeight, p5.WEBGL);
        setpg0(pg0);
        setpg1(pg1);
        setpg2(pg2);

		// use parent to render the canvas in this ref
		// (without that p5 will render the canvas outside of your component)
        // console.log(mainP5Canvas);
        pg0.strokeWeight(3);
        pg0.stroke(0);
        pg0.textAlign(p5.RIGHT);
        pg0.textFont(fontVCR);
        pg0.textSize(70);
        pg0.fill(...hudGreen);
        // cam.hide();

	};

	const draw = (p5) => {
        if (!pg0) {
            console.log('pg0 NOT READY');
            return;
        }
        let target = pg0;
        pg0.push();
        pg0.background(10);
        // pg0.image(cam, 0, 0, sketchWidth, sketchWidth * cam.height / cam.width);
        pg0.push();
        //   const canvas = document.createElement('canvas')
        //   canvas.width = 4096;
        //   canvas.height = 2048;
        //   const context = canvas.getContext('2d')
        const maxWidth = 75;
        const maxLines = 20;
        let toSplit = toDisplay;
        // if (cursor.show) toSplit += '_';
        const lines = textSplitter({
            terminalHistory: [...terminalHistory, {text: textContents, source: 'USER'}],
            text:toSplit,
            maxWidth,
            textSelection});
        let linesToWrite = lines;
        if (lines.length > maxLines) {
            linesToWrite = lines.slice(lines.length - maxLines, lines.length)
        }
        if (context) {
            linesToWrite.forEach((line, i) => writeText({ text: line.text,
                                                        highlight: line.highlight,
                                                        context,
                                                        lineNum: i,
                                                        fontSize,
                                                        color,
                                                        bgColor: '#0a0a0a',
                                                        isInput: false,
                                                        textSelection: line.textSelection,
                                                    }));
        }
        // writeToInputCanvas(pg0.drawingContext);
        pg0.pop();
        // pg0.image(c, 0, 0, width, height);
        // screenText('YOUR', pg0, 'LEFT', 'BOTTOM');
        // if (textToDisplay) {
        //     screenText(textToDisplay, pg0, 'CENTER', 'BOTTOM');
        // }
        // screenText('HERE', pg0, 'RIGHT', 'BOTTOM');
        if (showWidgets) {
            barWidget(pg0, p5);
            radarWidget(pg0, p5);
        }
        pg0.pop();
        // console.log(emShader);
        if (emShader && emShaderActive !== undefined && emShaderActive) {
            emShader.setUniform('tex0', target);
            emShader.setUniform('iTime', p5.millis() / 1000.0);
            pg1.shader(emShader);
            // console.log(pg1);
            pg1.rect(0, 0, sketchWidth, sketchHeight);
            target = pg1;
        }
        if (ddShader && ddShaderActive !== undefined && ddShaderActive) {
            ddShader.setUniform('tex0', target);
            ddShader.setUniform('iTime', p5.millis() / 1000.0);
            pg2.shader(ddShader);
            pg2.rect(0, 0, sketchWidth, sketchHeight);
            target = pg2;
        }
    
        // shader(emShader);
        // shader(ddShader);
        // rect gives us some geometry on the screen
        // rect(0,0,width,height);
        p5.image(target, -sketchWidth / 2, -sketchHeight / 2); // webGL mode requires weird offset parameters
        // image(pg2, 0, height / 2, img.width / 2, img.height / 2);
	};

	return (
        <Sketch ref={ref} setup={setup} draw={draw} preload={preload} >
        </Sketch>
    )
};
// function writeToInputCanvas(context) {
//     const color = 'red';
//     const fontSize = 200;
//     // console.log(inputCanvas);
//     // const canvas = document.getElementById(inputCanvas.canvas.id);
//     // console.log(canvas);
//     // const context = drawingContext;
//     const writeText = (text, context, lineNum) => {
//         context.beginPath();
//         context.font = `bold ${fontSize}px Roboto`;
//         context.textAlign = 'left';
//         context.textBaseline = 'middle';
//         context.fillStyle = color;
//         context.fillText(text, 0 + fontSize / 2, 0 + fontSize + (fontSize * lineNum));
//         context.stroke();
//     };
//     const linesToWrite = ['chode'];
//     linesToWrite.forEach((line, i) => writeText(line, context, i + 2));
//     // document.body.appendChild(canvas);
//     // return canvas;
// }

/** This renders text via canvas and projects it as a sprite */
function Screen(props) {
    const { p5CanvasCreated } = props
    const p5Canvas = document.getElementById('defaultCanvas0');

    const width = 3.70;
    const height = width/1.779359431;

    if (!p5CanvasCreated) {
        console.log('NO CANVAS YET')
    } else console.log('YES CANVAS');
    return (
        <mesh>
        <planeBufferGeometry attach="geometry" args={[width, height]} />
          {(p5Canvas) && 
            <meshBasicMaterial attach="material" >
                <canvasTexture attach="map" image={p5Canvas} premultiplyAlpha onUpdate={s => (s.needsUpdate = true)} />
            </meshBasicMaterial>
          }
      </mesh>
    )
  }
export { Screen, ScreenSketch };