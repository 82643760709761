const commandFunction = function({raw, argv, printToTerminal}) {
    printToTerminal(raw.replace('echo ', ''));
}
const commandName = 'echo';
const manPage = 'TODO: fill in the text for echo function.'
export {
    commandName,
    commandFunction,
    manPage,
};
