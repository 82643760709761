const resumeData = {};

const reggora = {
    name: 'Reggora',
    location: 'Fully Remote',
    timeStart: 'July 2021',
    timeEnd: 'Aug 2023',
    title: 'Senior Full Stack Developer',
    companyDescription: 'Reggora is a fintech company transforming the market for real estate valuation. Reggora provides a cloud-based, two-sided platform that streamlines the appraisal process for mortgage lenders and appraisers to dramatically reduce the mortgage lending timeline and make the home appraisal process more efficient.',
    details: [
        // "Architected, developed, and deployed a flexible modular data validation and review service from proof of concept code through executing on user feedback.",
        // "Implemented solutions to remedy a variety of platform wide inefficiencies and product needs requiring rapid understanding of existing code and learning of new technologies.",
        // // load testing, elasticssearch, front end security headers, feature flags implementation, various job refactors
        // "Mentored and collaborated with junior developers to improve their skills and productivity.",
        // "Refactored time expensive legacy jobs to use an event driven architecture massively decreasing pipeline bottlenecks.",
        // "Ran Deployments and coordinated with other teams to ensure smooth monolith releases while iterating on deployment documentation.",
        (<span>
            "Launched a flexible, modular data validation microservice for reviewing appraisals and more. Architected and developed project from internal proof of concept to <a target="new" href='https://www.reggora.com/blog/introducing-reggora-appraisal-review'><mark>enterprise product</mark></a>, working closely with cross-functional stakeholders."
        </span>
        ),
        { text: "Quickly ramped up on existing platform code and new technologies to optimize performance for growth in volume. Examples include:", children: [
            "Streaming data to Elasticsearch using FIFO queues to eliminate race conditions",
            "Refactored synchronous scheduled jobs into event driven architecture, delivering data to customers up to 50X faster.",
            "Implemented feature flag integration that was used department wide, enabling targeted deployments and microservice ci/cd.",
            "Resolved security vulnerabilities, including CORS, Content Security Policy, and maintaining packages",
            "Created platform load testing suite using k6, assessed results and identified bottlenecks",
        ]},
        "Mentored and collaborated with junior developers to improve their skills and productivity.",
        "Managed 10 company - wide releases for all teams, including support for critical systems, and maintained Release Manager documentation.",
        "Interviewed engineering candidates at all levels of seniority"
    ]
};

const brick = {
    name: 'BrickSimple',
    location: 'Philadelphia, PA',
    timeStart: 'Aug 2017',
    timeEnd: 'July 2021',
    title: 'Lead Developer',
    companyDescription: 'BrickSimple is a custom software development company specializing in mobile and web applications.  Clients include Fortune 500 companies, government agencies, and startups.',
    details: [
        // 'High degree of responsibility over pipeline processing client data.',
        'Implemented and maintained product through evolving requirements, architectural changes, and end user feedback.',
        'Earned a high degree of independence and ownership over client relationship and management of internal team members on the project.',
        'Carried out Async coordination with offshore team members.',
    ]
};

const hell = {
    name: 'Tayrex',
    location: 'Lindenwold, NJ',
    timeStart: 'May 2015',
    timeEnd: 'Aug 2017',
    title: 'Software Developer',
    companyDescription: 'Tayrex is a small software development company specializing in custom web applications.',
    details: [
        'Development of and support for custom software projects, generally web portals, for a variety of clients.',
        'Small company necessitated involvement at all levels of product.',
        'Emphasized fast responses when client issues arose.',
        'High degree of responsibility over projects and new employee management.',
    ]
};

const vsa = {
    name:"VSA",
    location:  "Westmont, NJ",
    timeStart:"Jan 2014",
    timeEnd: "July 2015",
    title: "Technical Specialist",
    companyDescription: "VSA Prospecting is a boutique call center specializing in customer service and sales.",
    details:[
        "Performed a full spectrum of IT responsibilities at this boutique call center.",
        "Used technical skills to identify and address inefficient business practices.",
        "Interfaced with outside software developer (Tayrex) for the design/testing of scheduling project.",
    ]
}




resumeData.jobs = [reggora, brick, hell, vsa];
resumeData.skills = [
    'Javascript/Typescript',
    'Node.js/Express',
    'React/Redux/Next.js',
    "Angular",
    'Python (FastAPI, Flask)',
    'SQL/MongoDB',
    'Docker/Linux',
    'Git + Gitlab CI/CD',
    'Event Driven Architecture',
    'Microservice Architecture',
    'Datadog',
    'Three.js',
    'D&D 5e Dungeon Master',
    'AWS [ Lambdas|SNS|SQS|S3|Elasticsearch|CloudFront ]',
];
export default resumeData;