import { useEffect, useState } from "react";

function getGamePadState(gamePadIndex = 0) {
    return navigator.getGamepads()?.[gamePadIndex];
}

export default function useGamepad(pollingRate = 100, gamePadIndex = 0) {
    const [gamepadState, setGamepadState] = useState({});

    // Remember the latest callback.
    // Set up the interval.
    useEffect(() => {
        function tick() {
            setGamepadState(getGamePadState(gamePadIndex));
        }
        if (pollingRate !== null) {
            let id = setInterval(tick, pollingRate);
            return () => clearInterval(id);
        }
    }, [pollingRate, gamePadIndex]);
    return gamepadState;
}