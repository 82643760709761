import React, {useState, useEffect} from "react";
import DisplayContainer from "../components/tvHead/displayContainer";
import tvHeadClient from "../services/tvHeadClient";
import useInterval from "../hooks/useInterval";
import '../styling/tvHead.css';
const TvHead = () => {
    const [currentImageData, setCurrentImageData] = useState({})
    const [secondsLeft, setSecondsLeft] = useState(0)
    
    useEffect(() => {
        if (secondsLeft > -1) return;
        tvHeadClient.getCurrentImageData({currentImageData, setCurrentImageData});
    }, [secondsLeft, currentImageData]);
    const forceRefresh = () => {
        tvHeadClient.getCurrentImageData({currentImageData, setCurrentImageData, forceRefresh: true});
    }
    useInterval(() => { (setSecondsLeft(Math.floor((Date.parse(currentImageData?.expiresAt) - new Date().getTime()) / 1000))) }, 100);
    return (
        <div className="slideshowContainer">
            {/* <span><mark>{String(secondsLeft + 1)}</mark></span> */}
            {/* <span><mark>{String(currentImageData?.Image?.id)}</mark></span> */}
            <DisplayContainer {...currentImageData?.Image}/>
            <div className="next-button" onClick={forceRefresh}>next ({String(secondsLeft + 1)})</div>
            <div className="ban-button" onClick={() => { console.log("ban") }}>ban</div>
            <div className="approve-button" onClick={() => { console.log("approve") }}>approve</div>
        </div>
    )
}

export default TvHead;