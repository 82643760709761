import React, { useRef, useState } from 'react'
import useWindowDimensions from '../hooks/useWindowDimensions';
import Sketch from '../services/reactP5';
import { div } from '../services/styleService';
import useGamepad from '../hooks/useGamepads';
import screenText from '../components/tvHead/widgets/screenText';
import EyeWidget from '../components/tvHead/widgets/eyeWidget';
import useTvHeadSetting from '../hooks/useTvHeadSetting';

const TvHeadWrapper = ({ children }) => {
    return (
        <div className="tv-head-wrapper">
            <div className="tv-head">
                <div className="tv-head-screen">
                    {children}
                </div>
            </div>
        </div>
    )
}

const textPadding = 20;
let emShaderActive = false;
let ddShaderActive = true;
const hudGreen = [15, 252, 3];
const showWidgets = false;
const showEyeWidget = true;
const showCamera = true;
const showText = true;
function radarWidget(target, p5) {
    const radarWidth = 200;

    target.push();
    // target.rectMode(CENTER);
    target.noFill();
    target.stroke(...hudGreen);
    target.translate(target.width - 110, 110);
    for (let radius = radarWidth; radius > 0; radius -= 50) {
        target.circle(0, 0, radius);
    }
    target.circle(0, 0, 150);
    target.circle(0, 0, 100);
    target.circle(0, 0, 50);
    // target.circle(0, 0, 10);
    target.fill(...hudGreen);
    target.triangle(0, -10, -8, 8, 8, 8);
    target.noFill();
    const pulseRadius = (p5.millis() / 7 % 500);
    if (pulseRadius <= radarWidth) {
        target.circle(0, 0, pulseRadius);
    }
    target.angleMode(p5.DEGREES);
    target.rotate((p5.millis() / 100) % 360);
    for (let lineAlpha = 0; lineAlpha <= 256; lineAlpha += 10) {
        target.stroke(...hudGreen, lineAlpha);
        target.rotate(1);
        target.line(0, 0, 0, radarWidth / 2);
    }
    target.pop();
}
const barWidget = function (target, p5) {
    const maxHeight = 70;
    const barWidth = 20;
    const barPadding = 15;
    const barCount = 4;
    let actualHeight;
    const milli = p5.millis();
    const speeds = [1000, 802, 600, 300];
    for (let index = 0; index <= barCount; index++) {
        actualHeight = (Math.sin(milli / speeds[index]) + 1) / 2 * maxHeight;
        target.rect(textPadding + index * (barWidth + barPadding), maxHeight - actualHeight + textPadding, barWidth, actualHeight);
    }
    target.rect(textPadding, maxHeight + textPadding + 5, barCount * (barWidth + barPadding) - barPadding, 5);
}




const TvHeadTextSketch = (props) => {
    const ref = useRef();
    const { height:windowHeight, width:windowWidth } = useWindowDimensions();
    console.log(ref);
    const { textSelection, textContents, terminalHistory } = props;
    const sketchWidth = windowWidth;
    const sketchHeight = windowHeight;
    const gamepad0 = useGamepad(100);
    console.log(windowHeight)

    const color = 'orange', fontSize = 50;
    let [pg0, setpg0] = useState(undefined);
    let [pg1, setpg1] = useState(undefined);
    let [pg2, setpg2] = useState(undefined);
    let [emShader, setEMShader] = useState(undefined);
    let [ddShader, setDDShader] = useState(undefined);
    let [fontVCR, setfontVCR] = useState(undefined);
    let [cam, setCam] = useState(undefined);
    let [eyeWidget, setEyeWidget] = useState(undefined);
    const [top_text, setTopText] = useTvHeadSetting('top-text', 'TRICK', 10000);
    const [middle_text, setMiddleText] = useTvHeadSetting('middle-text', 'OR', 10000);
    const [bottom_text, setBottomText] = useTvHeadSetting('bottom-text', 'TREAT', 10000);
    const [display_eye, setDisplayEye] = useTvHeadSetting('display-eye', 'true', 10000);
    const [display_cam, setDisplayCam] = useTvHeadSetting('display-cam', 'true', 10000);
    const context = (pg0) ? pg0.drawingContext : false;


    const preload = (p5) => {
        fontVCR = p5.loadFont(`${process.env.PUBLIC_URL}/assets/fonts/VCR_OSD_MONO_1.001.ttf`);
        ddShader = p5.loadShader(`${process.env.PUBLIC_URL}/assets/shaders/displayDistort.vert`, `${process.env.PUBLIC_URL}/assets/shaders/displayDistort.frag`);
        emShader = p5.loadShader(`${process.env.PUBLIC_URL}/assets/shaders/em.vert`, `${process.env.PUBLIC_URL}/assets/shaders/em.frag`);
        setEMShader(emShader);
        setfontVCR(fontVCR);
        setDDShader(ddShader);
        setEyeWidget(new EyeWidget());
    }

    const setup = (p5, canvasParentRef) => {
        p5.createCanvas(sketchWidth, sketchHeight, p5.WEBGL).parent(canvasParentRef);
        p5.noStroke();
        const tempCam = p5.createCapture(p5.VIDEO);
        tempCam.size(sketchWidth, sketchHeight);
        tempCam.hide();
        setCam(tempCam);
        pg0 = p5.createGraphics(sketchWidth, sketchHeight);
        pg1 = p5.createGraphics(sketchWidth, sketchHeight, p5.WEBGL);
        pg2 = p5.createGraphics(sketchWidth, sketchHeight, p5.WEBGL);
        eyeWidget.setup({p5});
        setpg0(pg0);
        setpg1(pg1);
        setpg2(pg2);

        // use parent to render the canvas in this ref
        // (without that p5 will render the canvas outside of your component)
        // console.log(mainP5Canvas);
        pg0.strokeWeight(3);
        pg0.stroke(0);
        pg0.textAlign(p5.RIGHT);
        pg0.textFont(fontVCR);
        pg0.textSize(150);
        pg0.fill(...hudGreen);
        // cam.hide();

    };
    const windowResized = (p5) => {
        p5.resizeCanvas(sketchWidth, sketchHeight);
    }
    const draw = (p5) => {
        if (!pg0) {
            console.log('pg0 NOT READY');
            return;
        }
        let target = pg0;
        pg0.push();
        pg0.background(10);
        if (String(display_cam.value).toLowerCase() === 'true') {
            pg0.image(cam, 0, 0, sketchWidth, sketchWidth * cam.height / cam.width);
        }
        if (String(display_eye.value).toLowerCase() === 'true') {
            eyeWidget.updateAndDraw({ controllerState: gamepad0, target: pg0, p5 });
        }
        pg0.pop();
        pg0.push();

        screenText(top_text.value, pg0, 'CENTER', 'TOP', p5);
        screenText(middle_text.value, pg0, 'CENTER', 'CENTER', p5);
        screenText(bottom_text.value, pg0, 'CENTER', 'BOTTOM', p5);

        pg0.pop();
        pg0.push();
        if (showWidgets) {
            barWidget(pg0, p5);
            radarWidget(pg0, p5);
        }
        pg0.pop();

        // console.log(emShader);
        if (emShader && emShaderActive !== undefined && emShaderActive) {
            emShader.setUniform('tex0', target);
            emShader.setUniform('iTime', p5.millis() / 1000.0);
            pg1.shader(emShader);
            // console.log(pg1);
            pg1.rect(0, 0, sketchWidth, sketchHeight);
            target = pg1;
        }
        if (ddShader && ddShaderActive !== undefined && ddShaderActive) {
            ddShader.setUniform('tex0', target);
            ddShader.setUniform('iTime', p5.millis() / 1000.0);
            pg2.shader(ddShader);
            pg2.rect(0, 0, sketchWidth, sketchHeight);
            target = pg2;
        }

        // shader(emShader);
        // shader(ddShader);
        // rect gives us some geometry on the screen
        // rect(0,0,width,height);
        p5.image(target, -sketchWidth / 2, -sketchHeight / 2); // webGL mode requires weird offset parameters
        // image(pg2, 0, height / 2, img.width / 2, img.height / 2);
    };

    return (
        <Sketch style={{maxHeight: '100%', maxWidth:'100%', overflowX: 'hidden', overflowY:'hidden'}} ref={ref} setup={setup} draw={draw} preload={preload} windowResized={windowResized}>
        </Sketch>
    )
};
export default TvHeadTextSketch;
