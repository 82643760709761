export default function screenText(textParams, target, horizontalLocation, verticalLocation, p5, textPadding = 10) {
    target.push();
    let textX = 0;
    let textY = 0;
    let xAlign = p5.LEFT;
    switch (horizontalLocation) {
        case 'LEFT':
            target.textAlign(p5.LEFT);
            xAlign = p5.LEFT;
            textX = 0 + textPadding;
            break;
        case 'RIGHT':
            target.textAlign(p5.RIGHT);
            xAlign = p5.RIGHT;
            textX = target.width - textPadding;
            break;
        case 'CENTER':
            target.textAlign(p5.CENTER);
            xAlign = p5.CENTER;
            textX = target.width / 2;
            break;
        default:
            break;
    }
    switch (verticalLocation) {
        case 'TOP':
            target.textAlign(xAlign, p5.TOP);
            textY = 0 + textPadding;
            break;
        case 'BOTTOM':
            target.textAlign(xAlign, p5.BOTTOM);
            textY = target.height - textPadding;
            break;
        case 'CENTER':
            target.textAlign(xAlign, p5.CENTER);
            textY = target.height / 2;
            break;
        default:
            break;
    }
    target.text(textParams, textX, textY);
    target.pop();
}