import { getCommands } from '../services/commandService';
const commandFunction = function({printToTerminal}) {
    printToTerminal('use man COMMAND_NAME to get more information about a command.');
    printToTerminal('Available commands:');
    const commands = getCommands();
    Object.keys(commands).forEach((commandKey) => {
        printToTerminal(` - ${commandKey}`);
    })
}
    
const commandName = 'help';
const manPage = 
'Name: Help' +
'\ncommand: help' +
'\n------------------------------' +
'\nDescription: List available commands'


export {
    commandName,
    commandFunction,
    manPage,
};
