import * as EchoCommand from '../commands/echo';
import * as ManCommand from '../commands/man';
import * as ResumeCommand from '../commands/resumeCommand';
import * as ganCommand from '../commands/ganCommand';
import * as ClearCommand from '../commands/clear';
import * as HelpCommand from '../commands/help';
const commands = {}
const writeToTerminal = function(text) {
    
}

const parseCommand = function(input) {
    const {command, printToTerminal} = input;
    console.log(command);
    console.log(commands);
    if (!command.length) return;
    const argv = command.split(' ');
    if (Object.keys(commands).includes(argv[0])) {
        commands[argv[0]].call({
            printToTerminal,
            raw: command,
            argv: argv,
            ...input
        })
    } else {
        printToTerminal('command not recognized');
    }
}
const getCommands = function() {
    return commands;
}
const registerCommand = function({commandName, commandFunction, manPage}) {
    commands[commandName] = {call: commandFunction, manPage: manPage};
}

registerCommand(HelpCommand);
registerCommand(ClearCommand);
registerCommand(ManCommand);
registerCommand(EchoCommand);
registerCommand(ResumeCommand);
registerCommand(ganCommand);



export { parseCommand, writeToTerminal, getCommands};
