import '../resume.css';
import resumeData from '../resumeData';
const {jobs, skills} = resumeData;
const JobDetail = ({detail}) => {
    if (detail?.children) {
        return (
            <li className="job-detail">
                {detail.text}
                <ul>
                    {detail.children.map((child, childIndex) => {
                        return (
                            <JobDetail detail={child} key={childIndex}/>
                        )
                    }
                    )}
                </ul>
            </li>
        )
    }

    return (
        <li className="job-detail">{detail}</li>
    )
}
const Job = ({job}) => {
    const {name, location, timeStart, timeEnd, title, details, companyDescription} = job;
    return (
        <>
        
        <div className="job-company"><mark>{name.toUpperCase()}</mark></div>
        <div className="job-info">
            <div className="job-title flareFont">{title}</div>
            <div className="job-time flareFont">{location} - {timeStart} – {timeEnd}</div>
        </div>
        <div className="job doublewide">
            <p className='job-company-description doublewide'>{companyDescription}</p>

            <ul className="job-details">

            {details.map((detail, detailIndex) => {
                
                return (
                    <JobDetail detail={detail} key={detailIndex}/>
                    )
                })}
            </ul>
        </div>
        </>
    )
}
const SectionTitle = (props) => {
    return (
        <div className="section-title"><mark>[ {props.children.toUpperCase()} ]</mark></div>
    )
}
const Resume = (props) => {
    return (
        <div className='resume-background'>
            <div className="container">
                <div className="resume-header invert-colors">
                    <div>
                        <h2 className="flareFont">Sean Bryan</h2>
                        <h3 className="flareFont">Senior Full Stack Developer</h3>
                    </div>
                    <div className="contact-info">
                        <div><a href="https://www.google.com/maps/place/39%C2%B054'49.5%22N+75%C2%B001'21.1%22W"><span class="material-symbols-outlined">
                            public
                        </span>39° 54' 49.4568'' N 75° 1' 21.0792'' W</a></div>
                        {/* <div>69 Edison Ave, Cherry Hill NJ 08002</div> */}
                        <div><a href="mailto:bryan.sean@gmail.com"><span class="material-symbols-outlined">
                            mail
                        </span>bryan.sean@gmail.com</a></div>
                        <div><a href='tel:609-320-1582'><span class="material-symbols-outlined">
                            phone_android
                        </span>(609) 320 1582</a></div>
                    </div>
                </div>
                <SectionTitle>Attributes</SectionTitle>
                <div className="attributes">
                    <div className="job-detail">Dedication to project excellence and client/user satisfaction</div>
                    <div className="job-detail">Employs creative problem solving and flexibility</div>
                    <div className="job-detail">Collaborative spirit with strong communication skills</div>
                    <div className="job-detail">Driven to learn new technologies/best practices</div>
                    <div className="job-detail">Generalist.</div>
                </div>
                <SectionTitle>Skills</SectionTitle>
                <div className="skills">
                    {skills.map((skill, skillIndex) => {
                        const skillclasses = 'skill'
                        return ( <div key={skillIndex} className={`${skillclasses} ${skill.includes('AWS') ? 'aws-skill':''}`}>{skill}</div>)
                    })}
                </div>
                <SectionTitle>Experience</SectionTitle>
                <div></div>
                <div className="experience doublewide">
                    {jobs.map((jobEntry, jobIndex) => (
                        <Job job={jobEntry} key={jobIndex}/>
                    ))}
                </div>
                <SectionTitle>Education</SectionTitle>
                <div className="education">

                    {/* <div className="job"> */}
                        <div className='school-info'>

                        <div className="school-name"><mark>{"RUTGERS STATE UNIVERSITY".toUpperCase()}</mark></div>
                        <div className="job-time flareFont">{"New Brunswick, NJ"} {2014}</div>
                        </div>

                        <div className="job-title flareFont">Bachelor of Science in Biotechnology - Bioinformatics Option</div>
                    {/* </div> */}
                </div>
            </div>
        </div>
    )
}
export {Resume};