import React, { useState, useEffect } from "react";
import useTvHeadSetting from "../hooks/useTvHeadSetting.js";

function SettingsField({ settingsKey }) {
    const [tvHeadSetting, updateSetting] = useTvHeadSetting(settingsKey, "DEFAULT");
    const [value, setValue] = useState(tvHeadSetting.value);
    useEffect(() => {
        setValue(tvHeadSetting.value);
    }, [tvHeadSetting]);
    const saveValue = (e) => {
        updateSetting({
            "key": tvHeadSetting.key,
            "value": value,
            "dataType": tvHeadSetting.dataType,
        });
    }
    return (
        <div className="settings-field">
            <label htmlFor={settingsKey}><mark>{settingsKey}</mark></label>
            <input type="text" id={settingsKey} value={value} onChange={(e) => { setValue(e.target.value) }} />
            {/* save button */}
            <button onClick={(e) => saveValue(e)}>save</button>
        </div>
    )
}

export default function TvHeadAdmin() {

    const settingsKeys = [
        'top-text',
        'middle-text',
        'bottom-text',
        'display-eye',
        'display-cam',
    ]

    return (
        <>
            {
                settingsKeys.map((settingsKey) => {
                    return (
                        <SettingsField key={settingsKey} settingsKey={settingsKey} />
                    )
                })}
        </>
    )
}