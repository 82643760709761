const qwerty = {
    'KeyQ': {
        row: 1,
        col: 1,
        pos: 'UP',
    },
    'KeyW': {
        row: 1,
        col: 2,
        pos: 'UP',
    },
    'KeyE': {
        row: 1,
        col: 3,
        pos: 'UP',
    },
    'KeyR': {
        row: 1,
        col: 4,
        pos: 'UP',
    },
    'KeyT': {
        row: 1,
        col: 5,
        pos: 'UP',
    },
    'KeyY': {
        row: 1,
        col: 6,
        pos: 'UP',
    },
    'KeyU': {
        row: 1,
        col: 7,
        pos: 'UP',
    },
    'KeyI': {
        row: 1,
        col: 8,
        pos: 'UP',
    },
    'KeyO': {
        row: 1,
        col: 9,
        pos: 'UP',
    },
    'KeyP': {
        row: 1,
        col: 10,
        pos: 'UP',
    },

    
    
    'KeyA': {
        row: 2,
        col: 1,
        pos: 'UP',
    },
    'KeyS': {
        row: 2,
        col: 2,
        pos: 'UP',
    },
    'KeyD': {
        row: 2,
        col: 3,
        pos: 'UP',
    },
    'KeyF': {
        row: 2,
        col: 4,
        pos: 'UP',
    },
    'KeyG': {
        row: 2,
        col: 5,
        pos: 'UP',
    },
    'KeyH': {
        row: 2,
        col: 6,
        pos: 'UP',
    },
    'KeyJ': {
        row: 2,
        col: 7,
        pos: 'UP',
    },
    'KeyK': {
        row: 2,
        col: 8,
        pos: 'UP',
    },
    'KeyL': {
        row: 2,
        col: 9,
        pos: 'UP',
    },
    'KeyZ':{
        row: 3,
        col: 1,
        pos: 'UP'
    },
    'KeyX':{
        row: 3,
        col: 2,
        pos: 'UP'
    },
    'KeyC':{
        row: 3,
        col: 3,
        pos: 'UP'
    },
    'KeyV':{
        row: 3,
        col: 4,
        pos: 'UP'
    },
    'KeyB':{
        row: 3,
        col: 5,
        pos: 'UP'
    },
    'KeyN':{
        row: 3,
        col: 6,
        pos: 'UP'
    },
    'KeyM':{
        row: 3,
        col: 7,
        pos: 'UP'
    },
    'Space':{
        row: 4,
        col: 5,
        pos: 'UP',
        width: 5
    }
};

// keyA = [
//     [
//         {
//             val: tab;
//             width
//         }
//     ],
//     [
//         val: capsLock
//     ]
// ]


const keyWidth = 20;
const rowOffset = 25;
const colOffset = 25;
const rowXOffset = [0,10,20,30];

export {
    qwerty,
    keyWidth,
    rowOffset,
    colOffset,
    rowXOffset
};