import React, { useRef, useState, useEffect } from 'react';
import { getWeather } from '../services/weatherService';
import dateformat from 'dateformat';
import useInterval from '../hooks/useInterval';

const dateFormat = dateformat;
const PhoneClock = (props) => {
    const { fontColor, bgColor} = props;
    const clockContainerStyle = {
        
    };
    const displayStyle = {
        width: '100%',
        height: '50%',
        fontWeight: 'Bold',
        color: fontColor,
        fontSize: '5em',
    }
    const timeSeparator = ':'
    const dateSeparator = '|'
    const [timeToDisplay, setTTD] = useState(new Date());
    useInterval(() => {setTTD(new Date())}, 1000);
    
    return (
        <div style={clockContainerStyle}>
            <div style={displayStyle}>
                { dateformat(timeToDisplay, `[HH${timeSeparator}MM]`) }
            </div>
            <div style={{
                background: fontColor,
                width: `${(timeToDisplay.getSeconds()/60)*90 + 10}%`,
                // height: `2em`,
                display: 'flex',
                justifyContent: 'space-between',
                flexWrap: 'no-wrap',
                fontSize: '2em',
                alignItems: 'baseline',
                color: bgColor,
                paddingBottom: '0.2em'
            }}>
                <div>[</div>
                <div>]</div>
            </div>
            <div style={displayStyle}>
                { dateformat(timeToDisplay, `[mm${dateSeparator}dd]`)}
            </div> 
        </div>

    )
}
const Phone = (props) => {
    return (
        <>
            <PhoneClock {...props}></PhoneClock>
            <WeatherWidget {...props}/>
        </>
    )
}

const WeatherWidget = (props) => {

    const { fontColor, bgColor } = props;
    const [weather, setWeather] = useState({current: {}, hourlies: [], loaded: false});
    useEffect(() => {
        getWeather(setWeather);
    }, []);
    // update weather every 10 minutes.
    useInterval(() => {getWeather(setWeather)}, 1000 * 60 * 10);

    if (!weather.loaded) return null;
    const hourlies = weather.hourlies.map((hourly, hourlyIndex) => {
        return (
            <div style={{
                background: fontColor,
                gridColumnStart: hourlyIndex + 1,
                gridColumnEnd: hourlyIndex + 1,
                height: `${(50 + hourly.fillpct/2)}%`,
                display: 'flex',
                flexDirection: 'column',
                flexWrap: 'nowrap',
                justifyContent: 'space-between'
                }}
                key={hourlyIndex}>
                    <div>{ Math.round(hourly.temp)}°</div>
                    <div>{dateFormat(new Date(hourly.dt*1000), 'HH:MM')}</div>
            </div>
        )
    })
    return (
    <div style={{
        color: bgColor,
        display: 'grid',
        // background: 'salmon',
        columnGap: '20px',
        padding: '10px',
        height: '40%',
        alignItems: 'end',
        fontWeight: 'bold',
        fontSize: '1.5em',
    }}>
        { hourlies }
    </div>
    )
}

export {Phone};