import './App.css';
import React, { useRef, useState, useMemo, Suspense} from 'react'
import { Canvas, useThree, extend, useLoader} from 'react-three-fiber'
import { Html } from '@react-three/drei';
import * as THREE from 'three'
import { BlendFunction } from 'postprocessing'
import { EffectComposer, Bloom, Noise, ChromaticAberration } from '@react-three/postprocessing'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls'
import * as storageService from './services/storageService';
import { ScreenSketch, Screen } from './components/screen';
import * as commandSvc from './services/commandService'; 
import { KeyBoard } from './components/keyboard';
import { qwerty } from './components/keyParams';
import Environment from './components/environment';
import { Switch, Route, Link, useHistory } from 'react-router-dom'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader'
import { Phone } from './components/phone'
import { Resume } from './components/resume'
import { isMobile } from "react-device-detect";
import { userSignifier } from './services/termService';
import { CirclesSketch } from './pages/circleMovers';
import TvHead from './pages/tvHead';
import TvHeadTextSketch from './pages/tvHeadComs';
import TvHeadAdmin from './pages/tvHeadAdmin';
const Sockets = React.lazy(() => import('./components/sockets'));


function MyEffectComposer() {
  return (
    <EffectComposer>
      <Bloom luminanceThreshold={.5} luminanceSmoothing={0.2} height={1080} intensity={.5} opacity={3}/>
      <Noise opacity={0.02} />
      <ChromaticAberration
        blendFunction={BlendFunction.NORMAL} // blend mode
        offset={[0.0002, 0.002]} // color offset
      />
    </EffectComposer>
  )
}




extend({ OrbitControls });
function CameraControls() {
  const { gl: { domElement }, camera } = useThree();
  // useFrame(({ gl, scene, camera }) => {
  //   if (debugStats) myStats.update();
  // }, 1);
  return <orbitControls args={[camera, domElement]} />
}
const DebugTextField = React.forwardRef((props, ref) => {
// function DebugTextField(props) {
  const { submitCommand, printToTerminal, getCommandHistory, kbKeys, setKbKeys, setTextSelection} = props;
  const [historyIndex, setHistoryIndex] = useState(0);
  const [textContentsStash, setTextContentsStash] = useState('');
  const history = useHistory();

  const hideTextArea = true;
  const textStyle = hideTextArea ? {
    opacity: 0,
    width: 0,
    position: 'absolute',
    top: 0,
    right: 0} : {position: 'absolute',
    top: 0,
    right: 0};
  const handleChange = function(event) {
    // console.log(event.target.value);
    setTextContents(event.target.value);
    const {selectionStart, selectionEnd } = event.target;
    setTextSelection([selectionStart + userSignifier.length, selectionEnd + userSignifier.length]);
  }
  const clearInput = function() {
    setTextContents('')
  }
  const updateKeyState = function(keyCode, position) {
    const keyEntry = kbKeys[keyCode];
    if (!keyEntry) return;
    kbKeys[keyCode] = {...keyEntry, pos: position}
    setKbKeys(kbKeys);
  }
  const handleKeyUp = function(event) {
    updateKeyState(event.code, 'UP');
    const {selectionStart, selectionEnd } = event.target;
    setTextSelection([selectionStart + userSignifier.length, selectionEnd + userSignifier.length]);
  }
  const handleKeyDown = function(event) {
    const {selectionStart, selectionEnd } = event.target;
    setTextSelection([selectionStart + userSignifier.length, selectionEnd + userSignifier.length]);
    updateKeyState(event.code, 'DOWN');
    if (event.code === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      setHistoryIndex(0);
      submitCommand({command: textContents, ...props, history});
      setTextContentsStash('')
      clearInput();
    }
    if (event.code === 'ArrowUp' && !event.shiftKey) {
      console.log(`history Index ${historyIndex}`)
      if (historyIndex === 0) {
        setTextContentsStash(textContents);
      }
      event.preventDefault()
      const getCommandHistoryResult = getCommandHistory(historyIndex - 1 )
      if (!getCommandHistoryResult) return;
      setTextContents(getCommandHistoryResult.text);
      setHistoryIndex(historyIndex -1);
    }
    if (event.code === 'ArrowDown' && !event.shiftKey) {
      console.log(`history Index ${historyIndex}`)
      if (historyIndex + 1 > 0) return;
      setHistoryIndex(historyIndex + 1);
      if (historyIndex + 1 === 0) {
        setTextContents(textContentsStash);
      } else {
        const getCommandHistoryResult = getCommandHistory(historyIndex + 1)
        if (getCommandHistoryResult) setTextContents(getCommandHistoryResult.text);
      }
      event.preventDefault()
    }
    if (event.code === 'KeyC' && event.ctrlKey) {
      printToTerminal('exit')
      event.preventDefault();
      clearInput();
    }
  }
  const {textContents, setTextContents} = props ;
  // textAreaElement.current.onKeyDown
  return (
    <textarea ref={ref} 
        style={textStyle} 
      value={textContents}
      onChange={handleChange}
      onKeyUp={handleKeyUp}
      onKeyDown={handleKeyDown}/>
  )
});

function MainCanvas(props) {
  const { textfieldFocus} = props;
  return (
    <Canvas
    onClick={(e) => textfieldFocus(e)}
    camera={{ fov: 75, position: [0, .1, 4]}}
    gl={{ powerPreference: "high-performance", alpha: false, antialias: false, stencil: false, depth: false }}
    onCreated={({ gl, scene }) => {
      textfieldFocus(null);
      gl.toneMapping = THREE.ACESFilmicToneMapping
      gl.outputEncoding = THREE.sRGBEncoding
      // scene.background = new THREE.Color('#373737')
      // scene.background.convertSRGBToLinear()
    }}>
    {/* <color attach="background" args={["#050505"]} /> */}
    <ambientLight intensity={.2} />
    {/* <spotLight position={[10, 10, 10]} angle={0.15} penumbra={1} /> */}
    <pointLight position={[-10, -10, -10]} inensity={1}/>
    <group >
      <Screen {...props}/>
      <Desk {...props}></Desk>
      <Suspense fallback={<Html center>loading...</Html>}>
        <KeyBoard {...props}/>
        <Computer {...props}></Computer>
        <Environment background={true}/>
      </Suspense>
    </group>
    <CameraControls/>
    <MyEffectComposer/>
  </Canvas>
  )
}
function Desk(props) {
  return (
    <mesh position={[0,-3,0]}>
    <boxBufferGeometry attach="geometry" args={[10, 1, 7]}/>
    <meshPhysicalMaterial
        attach="material"
        metalness={0.2}
        roughness={0.7}
        color={'dark-brown'}
        />
    </mesh>
  )
}
function Computer(props) {
  const { nodes } = useLoader(GLTFLoader, '/assets/consoleModel.glb', loader => {
    const dracoLoader = new DRACOLoader()
    dracoLoader.setDecoderPath('/draco-gltf/')
    loader.setDRACOLoader(dracoLoader)
  })
  console.log('nodes');
  console.log(nodes);
  return (
    <group>
      <mesh
        geometry={nodes.Computer.geometry}
        rotation={nodes.Computer.rotation}>
        <meshStandardMaterial
          attach="material"
          metalness={0.0}
          roughness={0.4}
          color={'beige'}
          side={THREE.DoubleSide} />
      </mesh>
      <mesh 
        geometry={nodes.wiremesh.geometry}>
        <meshStandardMaterial
          attach="material"
          color={'gray'}
          clearcoat={1.0}
        />
      </mesh>
    </group>
  )
}

function TerminalModel() {
  const [textContents, setTextContents] = useState('')
  const storedTerminalHistory = useMemo(() => storageService.getByKey('terminalHistory', []), [])
  const [terminalHistory, setTerminalHistory] = useState(storedTerminalHistory);
  const [p5CanvasCreated, setP5CanvasCreated] = useState(false);
  const [textSelection, setTextSelection] = useState([0,0]);
  const ref = useRef();
  const [kbKeys, setKbKeys] = useState(qwerty);
  // const [showMobileModal, setShowMM] = useState(true);
  const [showMobileModal, setShowMM] = useState(isMobile);
  function setSaveTerminalHistory(th) {
    setTerminalHistory(th);
    storageService.setByKey('terminalHistory', th); 
  }

  const clearTerminalHistory = function() {
    setSaveTerminalHistory([]);
  }

  const textfieldFocus = function(e) {
    if (e) e.preventDefault();
    ref.current.focus();
    ref.current.selectionEnd = textContents.length;
    ref.current.selectionStart = textContents.length;
  }

  const getCommandHistory = function(index) {
    const userCommands = terminalHistory.filter(message => message.source === 'USER');
    if (userCommands[userCommands.length + index]) {
      return userCommands[userCommands.length + index];
    }
  }

  const printToTerminal = function(message) {
    terminalHistory.push({text: message, source: 'MACHINE'});
    setSaveTerminalHistory(terminalHistory);
  }

  const submitCommand = function(input) {
    const {command} = input;
    terminalHistory.push({text: command, source: 'USER'});
    setSaveTerminalHistory(terminalHistory);
    commandSvc.parseCommand({...input, command, printToTerminal});
  }

  return (
      <>
        {(showMobileModal) ? (<MobileModal setShowMM={setShowMM}/>) : ''}
        <link rel="preconnect" href="https://fonts.gstatic.com"/>
        <link href="https://fonts.googleapis.com/css2?family=Xanh+Mono&display=swap" rel="stylesheet"/>
        <MainCanvas { ...{textfieldFocus, terminalHistory, textContents, kbKeys, p5CanvasCreated}} />
        <div style={{display: 'none', height: 0, width: 0, opacity: 0}}>
          <ScreenSketch 
            sketchWidth={1920}
            sketchHeight={1080}
            textSelection={textSelection} 
            {...{textfieldFocus, terminalHistory, textContents, kbKeys, setP5CanvasCreated}}>
          </ScreenSketch>
        </div>
        <DebugTextField ref={ref} {...{clearTerminalHistory, terminalHistory, setTextSelection, textContents, setTextContents, submitCommand, printToTerminal, getCommandHistory, kbKeys, setKbKeys, setSaveTerminalHistory}} />
      </>
  );
}
function MobileModal({setShowMM}) {
  const history = useHistory();
  function goToResume() {
    history.push('/resume');
  }
  function dismissMM() {
    setShowMM(false);
  }
  return (
    <div className="modal-container" onClick={dismissMM}>
      <div className="mobile-modal">
        <h2>WARNING</h2>
        <div className="warning-text">
        <p>This (really cool, <mark>totally impressive</mark>) content will most likely not behave correctly on mobile</p>
        <p>Please come back some time on a computer and check it out</p>
          </div>
        <div className="modal-button-row">
          <button onClick={dismissMM}>forge ahead</button>
          <button onClick={goToResume}>just take me to the resume</button>
        </div>
      </div>
    </div>
  )
}
function App() {
  return (
    <Switch>
      <Route path="/test">
        <div>
          chode
        </div>
        <div>
          <Link to="/">Home</Link>
        </div>
      </Route>
      <Route path="/tv-head">
        <TvHead></TvHead>
      </Route>
      <Route path="/resume">
        <Resume></Resume>
      </Route>
      <Route path="/phone">
        <Phone fontColor={'orange'} bgColor={'#1b2631'}></Phone>
      </Route>
      <Route path="/movers">
        <CirclesSketch/>
      </Route>
      <Route path="/tv-head-admin">
        <TvHeadAdmin/>
      </Route>
      <Route path="/tv-head-text">
        <TvHeadTextSketch />
      </Route>
      <Route path="/sockets">
        <Suspense fallback={<div>Loading...</div>}>
          <Sockets fontColor={'orange'} bgColor={'#1b2631'}></Sockets>
        </Suspense>
      </Route>
      <Route path="/">
        <TerminalModel />
      </Route>
      <Route path="*">
        404 buddy
      </Route>
    </Switch>
  )
}

export default App;
