import { getCommands } from '../services/commandService';

const commandFunction = function({raw, argv, printToTerminal}) {
    const functionName = argv[1];
    const commands = getCommands();
    if (!Object.keys(commands).includes(functionName)) {
        printToTerminal('no matching programs found');
        return;
    }
    const commandReference = commands[functionName];
    if (commandReference.manPage) {
        printToTerminal(commandReference.manPage);
    } else {
        printToTerminal(`command (${functionName})exists, but has no man page`);
    }
}

const commandName = 'man';
const manPage = 'whatever man';

export {
    commandName,
    commandFunction,
    manPage
}